import React, { Component } from 'react';
import { Link } from "gatsby";
import styles from './MenuItem.module.css';

class MenuItem extends Component {
    state = {  
        active: false,
    }
    render() { 
        // let style = this.state.emoji1.selected ? "selected" : "unselected";
        let x = this.props.name
        let name = x.toLowerCase();
        return ( 
            <Link
                className={styles.selected}
                to={`/` + name}>
                {this.props.name}
            </Link>
         );
    }
}
 
export default MenuItem;

