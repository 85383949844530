import React, { Component } from 'react'
import './MenuModal.css'
import { FaTimes, FaBars } from 'react-icons/fa'
import AriaModal from 'react-aria-modal'
import Img from 'gatsby-image'
import MenuItem from './MenuItem'

class menuModal extends Component {
  state = {
    modalActive: false,
  }
  activateModal = () => {
    this.setState({ modalActive: true })
  }

  deactivateModal = () => {
    this.setState({ modalActive: false })
  }

  getApplicationNode = () => {
    return document.getElementById('application')
  }

  render() {
    console.log(this.props)
    const modal = this.state.modalActive ? (
      <AriaModal
        titleText="Modal One"
        onExit={this.deactivateModal}
        initialFocus="#demo-one-deactivate"
        getApplicationNode={this.getApplicationNode}
      >
        <div id="demo-one-modal" className="modal">
          <div className="top">
            <Img
              fluid={this.props.props.imageOne.childImageSharp.fluid}
              alt={''}
              className="logo"
            />
            <FaTimes
              size={35}
              className="FaTimes"
              id="demo-one-deactivate"
              onClick={this.deactivateModal}
            />
          </div>
          <div className="modal-body">
            <ul className="list">
              <MenuItem name="Fashion" />
              <MenuItem name="Tech" />
              <MenuItem name="Culture" />
              <MenuItem name="About" />
            </ul>
          </div>
          <div className="footer">© 2019 LENGJAI. All rights reserved.</div>
        </div>
      </AriaModal>
    ) : (
      false
    )
    return (
      <div className="barsContainer">
        <FaBars className="FaBars" size={25} onClick={this.activateModal} />
        {modal}
      </div>
    )
  }
}

export default menuModal
