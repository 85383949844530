import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Index } from 'elasticlunr'
import { Link } from 'gatsby'
import styles from './Search.module.css'

// Search component
export default class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
    }
  }

  render() {
    return (
      <div>
        <input
          className={styles.input}
          style={{
            position: `relative`,
          }}
          type="text"
          placeholder="Search"
          value={this.state.query}
          onChange={this.search}
        />
        <ul className={styles.list}>
          {this.state.results.map(page => (
            <li key={page.id} className={styles.listItemContainer}>
              <Link className={styles.listItem} to={`/articles/` + page.path}>{page.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex)

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }
}

Search.propTypes = {
  searchIndex: PropTypes.object,
}
