import React from 'react'
import styles from './footer.module.css'
import { Link } from 'gatsby'
import { FaInstagram, FaFacebook, FaGithub } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer className={styles.container}>
      <Link to={`/about`} className={styles.about}>
        <span>About</span>
      </Link>

      <div className={styles.social}>
        <a
          href={'https://instagram.com/seanngpack'}
          target="_blank"
          aria-label="instagram"
          rel="noopener noreferrer" //for security lol
          className={styles.instaContainer}
        >
          <FaInstagram size={30} />
        </a>
        <a
          href={'https://facebook.com/seanngpack'}
          target="_blank"
          aria-label="facebook"
          rel="noopener noreferrer" //for security lol
          className={styles.facebookContainer}
        >
          <FaFacebook size={30} />
        </a>
        <a
          href={'https://github.com/seanngpack'}
          target="_blank"
          aria-label="github"
          rel="noopener noreferrer" //for security lol
          className={styles.githubContainer}
        >
          <FaGithub size={30} />
        </a>
      </div>
      <div className={styles.copyright}>
      © 2019 LENGJAI. All rights reserved.
      </div>
    </footer>
  )
}

export default Footer
