import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import NavbarFixed from './Navbar/NavbarFixed'
import Footer from '../components/Footer/footer'
import favicon60 from '../images/60x60.png'
import CookieBanner from 'react-cookie-banner'

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        imageOne: file(relativePath: { eq: "LENGJAI-black.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        site {
          siteMetadata {
            title
          }
        }
        siteSearchIndex {
          index
        }
      }
    `}
    render={data => (
      <div>
        <Helmet
          meta={[
            // { name: 'description', content: 'Your description here' },
            {
              name: 'google-site-verification',
              content: 'WoLY_ceNUaBMCt6LOONDSeKNJmRavNpW2FhECfQPCEU',
            },
          ]}
          script={[
            {
              async: '',
              defer: 'defer',
              src: '//platform.instagram.com/en_US/embeds.js',
            },
          ]}

          // link={[
          //   {
          //     rel: 'icon',
          //     type: 'image/png',
          //     sizes: '60x60',
          //     href: `${favicon60}`,
          //   },
          // ]}
        >
          <html lang="en" />
        </Helmet>
        <NavbarFixed data={data} />
        <div>{children}</div>
        <div>
          <CookieBanner
            styles={{
              banner: { backgroundColor: 'rgba(60, 60, 60, 0.8)', position: 'fixed', height: '100px', left: 0, bottom: 0, },
              message: {  fontWeight: 400, lineHeight: '100px', },
              button: {padding: ' 10px 20px', lineHeight: '10px', borderRadius: '3px', }

            }}            
            message="Lengjai uses 🍪's"
            onAccept={() => {}}
            cookie="user-has-accepted-cookies"
            dismissOnScroll={false}
          />
        </div>
        <Footer />
      </div>
    )}
  />
)
