import React, { Component } from 'react'
import { Link } from 'gatsby'
import styles from './NavbarFixed.module.css'
import Img from 'gatsby-image'
import { FaInstagram } from 'react-icons/fa'
import MenuModal from '../MenuModal/MenuModal'
import Search from '../Search/Search'
import { FaSearch } from 'react-icons/fa'
import onClickOutside from "react-onclickoutside";
class Navbar extends Component {
  state = {
    active: false,
  }

  handleClickOutside = evt => {
    this.setState({ active: false })
  };


  onClickHandler = () => {
    this.setState({ active: !this.state.active })
  }

  render() {
    return (
      <div className={styles.Headroom}>
        <div className={styles.headContainer}>
          <div className={styles.menuModal}>
            <MenuModal props={this.props.data} />
          </div>
          <div className={styles.list}>
            <Link className={styles.Link} to={`/fashion/`}>
              Fashion
            </Link>
            <Link className={styles.Link} to={`/reviews/`}>
              Reviews
            </Link>
            <Link className={styles.Link} to={`/tech/`}>
              Tech
            </Link>
            <Link className={styles.Link} to={`/culture/`}>
              Culture
            </Link>
          </div>
          <Link className={styles.LogoLink} to={`/`}>
            <Img
              fluid={this.props.data.imageOne.childImageSharp.fluid}
              alt={''}
              className={styles.logo}
            />
          </Link>
          <a
            href={'https://instagram.com/seanngpack'}
            target="_blank"
            aria-label="instagram"
            rel="noopener noreferrer" //for security lol
            className={styles.instaContainer}
          >
            <FaInstagram size={30} className={styles.FaInstagram} />
          </a>
          <div className={styles.searchIcon}>
            <FaSearch
              className="FaSearch"
              size={25}
              onClick={this.onClickHandler}
            />
          </div>
        </div>
        {this.state.active ? (
          <div className={styles.searchContainer}>
            <Search searchIndex={this.props.data.siteSearchIndex.index} />
          </div>
        ) : null}
      </div>
    )
  }
}

export default onClickOutside(Navbar);
